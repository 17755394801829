import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { LocText } from '../../Components/LocText';
import { PageInfo } from '../../Components/PageInfo/PageInfo';
import { useLanguageContext } from '../../Hooks/Translation';
import { DataService } from '../../Services/DataService';
import { IWizardPageProps } from './IWizardPageProps';
import { WizardTemplate } from './WizardTemplate';

//import styles from './WelcomePage.module.scss';

export interface IQuestionTwoPageProps extends IWizardPageProps {}

export function QuestionTwoPage(props: IQuestionTwoPageProps) {
    const { t } = useLanguageContext();
    const [valid, setValid] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
    const [options, setOptions] = useState<IChoiceGroupOption[] | undefined>(undefined);

    useEffect(() => {
        const data = [
            { key: '1', text: t('Question2_InUse')?.text || 'Yes' },
            { key: '0', text: t('Question2_unused')?.text || 'No' },
        ];
        setOptions(data);
    }, [t]);

    useEffect(() => {
        if (!!selectedKey) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [selectedKey]);

    const onNextClick = async () => {
        const datasvc = new DataService();

        try {
            setSaving(true);
            setErrorText(undefined);
            await datasvc.updateItemStep2({
                id: props.data.id,
                inUse: selectedKey === '1' ? true : false,
            });
            if (selectedKey === '1') {
                props.goToNext();
            } else {
                props.goToSuccess();
            }
            return;
        } catch {
            setErrorText(t('Question2_FailedToSave')?.text || t('FailedToSave')?.text || 'Failed to save answer!');
        }
        setSaving(false);
    };
    const onPrevClick = () => {
        props.goToPrev();
    };

    return (
        <WizardTemplate
            img={props.img}
            showNext={true}
            disableNext={!valid || saving}
            showPrev={true}
            disablePrev={false}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            step={1}
            errorText={errorText}
            showSpinner={saving}
        >
            <div className="question-content">
                <div className="question-text">
                    <div className="question-text-inner">
                        <LocText text="Question2" />
                    </div>
                </div>
                <div className="question-body">
                    <ChoiceGroup
                        selectedKey={selectedKey}
                        options={options}
                        onChange={(ev, option) => {
                            if (option) {
                                setSelectedKey(option?.key);
                            }
                        }}
                    />
                </div>

                <div className="flex-filler"></div>

                <PageInfo data={props.data} />
            </div>
        </WizardTemplate>
    );
}
