import * as React from 'react';
import './ListViewItem.scss';
//import styles from './ListViewItem.module.scss';

export interface IListViewItemProps {
    clickable?: boolean;

    onSelect: () => void;
}

export interface IListViewItemState {}

export default class ListViewItem extends React.Component<
    React.PropsWithChildren<IListViewItemProps>,
    IListViewItemState
> {
    constructor(props: IListViewItemProps) {
        super(props);

        this.state = {};
    }

    public render(): React.ReactElement<IListViewItemProps> {
        const { clickable, children } = this.props;

        const classes = new Set(['no-button', 'list-item']);
        if (clickable) {
            classes.add('clickable');
        }

        return (
            <button
                className={Array.from(classes).join(' ')}
                disabled={!clickable}
                onClick={() => {
                    if (clickable) {
                        this.props.onSelect();
                    }
                }}
            >
                {children}
            </button>
        );
    }
}
