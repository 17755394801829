import { IStackStyles, IStackTokens, Stack, ThemeProvider } from '@fluentui/react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { Header } from './Components/Header';
import { LanguageProvider } from './Hooks/Translation';
import { useIsSignedIn } from './Hooks/useIsSignedIn';
import { NoPage } from './Pages/NoPage';
import { WelcomePage } from './Pages/WelcomePage';
import { WizardPage } from './Pages/WizardPage';
import { palfingerTheme } from './Theme';

const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        width: '1024px',
        margin: '0 auto',
        color: '#605e5c',
        backgroundColor: 'white',
        minHeight: '500px',
    },
};

export interface IAppProps {}

export const App: React.FunctionComponent<IAppProps> = (props: IAppProps) => {
    const [isSignedIn] = useIsSignedIn();

    return (
        <ThemeProvider className="App" theme={palfingerTheme}>
            <LanguageProvider>
                <Stack horizontalAlign="start" verticalAlign="start" styles={stackStyles} tokens={stackTokens}>
                    <Stack style={{ width: '100%' }} className="header">
                        <Header />
                    </Stack>
                    <Stack style={{ width: '100%' }} className="content">
                        {!isSignedIn ? (
                            <div className="center">
                                <div>Please Log in</div>
                            </div>
                        ) : (
                            <Routes>
                                <Route index element={<WelcomePage />} />
                                <Route path="item/:id" element={<WizardPage />} />
                                <Route path="*" element={<NoPage />} />
                            </Routes>
                        )}
                    </Stack>
                    <Stack></Stack>
                </Stack>
            </LanguageProvider>
        </ThemeProvider>
    );
};
