import { useEffect, useState } from 'react';
import { useLanguageContext } from '../Hooks/Translation';
import { ILOCValue } from '../Interfaces/ILOC';

export interface ILocTextProps {
    text: string;
}

export const LocText: React.FunctionComponent<ILocTextProps> = (props: React.PropsWithChildren<ILocTextProps>) => {
    const { lang, t } = useLanguageContext();
    const transkey = props.text;
    const [text, setText] = useState<ILOCValue | undefined>({ text: undefined, html: undefined });
    useEffect(() => {
        function progressText(): void {
            setText(t(transkey));
        }
        progressText();
    }, [transkey, lang, t]);
    return (
        <>
            {text?.text && <span>{text.text}</span>}
            {text?.html && <span dangerouslySetInnerHTML={{ __html: text?.html }}></span>}
        </>
    );
};
