import { Stack } from '@fluentui/react';
import { Login } from '@microsoft/mgt-react';
import * as React from 'react';
import { LocDropDown } from './LocDropDown';
import { LocText } from './LocText';

export interface IHeaderProps {}

export const Header: React.FunctionComponent<IHeaderProps> = (props: React.PropsWithChildren<IHeaderProps>) => {
    return (
        <Stack
            horizontalAlign="space-between"
            horizontal
            verticalAlign="center"
            style={{ width: '100%', justifyItems: 'space-between' }}
        >
            <div>
                <img src="/assets/Palfinger_Logo.png" height={'30px'} alt="" />
            </div>
            <div>
                <h1>
                    <LocText text="HeadLine" />
                </h1>
            </div>
            <div>
                <LocDropDown />
                <Login />
            </div>
        </Stack>
    );
};
