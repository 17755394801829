import { Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import ListViewItem from '../../Components/ListView/ListViewItem';
import { LocText } from '../../Components/LocText';
import { useLanguageContext } from '../../Hooks/Translation';
import { IMigrationDate } from '../../Interfaces/IMigrationDate';
import { DataService } from '../../Services/DataService';
import { IWizardPageProps } from './IWizardPageProps';
import { WizardTemplate } from './WizardTemplate';
//import styles from './PickDatePage.module.scss';

export interface IPickDatePageProps extends IWizardPageProps {}

export function PickDatePage(props: IPickDatePageProps) {
    const { t } = useLanguageContext();
    const [dates, setDates] = useState<IMigrationDate[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [saving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    useEffect(() => {
        const datasvc = new DataService();
        setLoading(true);
        datasvc.getRefDateItems(props.data.migrationType).then(x => {
            setDates(x);
            setLoading(false);
        });
    }, [props.data.migrationType]);

    const onPrevClick = () => {
        props.goToPrev();
    };
    const onItemSlectedClick = async (id: number, datestring: string) => {
        const datasvc = new DataService();

        try {
            setErrorText(undefined);
            setSaving(true);
            await datasvc.updateItemStep3({
                id: props.data.id,
                refDateId: id,
                refDate: new Date(datestring),
            });

            props.goToNext();
            return;
        } catch {
            setErrorText(
                t('PickDatePage_FailedToSave')?.text || t('FailedToSave')?.text || 'Failed to save Selection!'
            );
        }
        setSaving(false);
    };

    return (
        <WizardTemplate
            img={props.img}
            showNext={false}
            disableNext={false}
            showPrev={true}
            disablePrev={false}
            onPrevClick={onPrevClick}
            showSpinner={saving}
            errorText={errorText}
            step={2}
        >
            <div className="question-content">
                <div className="question-text">
                    <div className="question-text-inner">
                        <LocText text="SelectTime" />
                    </div>
                </div>
                <div className="question-body">
                    {loading ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <div className="scrollable">
                            {!!dates &&
                                dates.map((d, i) => {
                                    return (
                                        <ListViewItem
                                            key={'daterange' + i}
                                            clickable={true}
                                            onSelect={() => onItemSlectedClick(d.id, d.migrationStartDate)}
                                        >
                                            <strong>{new Date(d.migrationStartDate).toLocaleDateString()}</strong>
                                            <div>
                                               
                                            </div>
                                        </ListViewItem>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </div>
        </WizardTemplate>
    );
}
