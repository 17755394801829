import { Providers } from '@microsoft/mgt-element';
import { IAnswer1, IAnswer2, IAnswer3, IBaseAnswer } from "../Interfaces/IAnswer";
import { IMigrationDate } from '../Interfaces/IMigrationDate';
import { IMigrationList } from '../Interfaces/IMigrationItem';
import { MigrationStatus } from "../Interfaces/MigrationStatus";
import { IDataService } from './IDataService';

const useMockData = false;

const demoMigrationListData: IMigrationList[] = [
    {
        id: 1,
        title: 'Title1',
        migrationStatus: MigrationStatus.New,
        sourceURL: 'url1',
        targetURL: 'url2',
        migrationType:1
    },
    {
        id: 2,
        title: 'Title2',
        migrationStatus: MigrationStatus.Evaluation,
        sourceURL: 'url1',
        targetURL: 'url2',
        migrationType:1
    },
    {
        id: 3,
        title: 'Title3',
        migrationStatus: MigrationStatus.Evaluation,
        sourceURL: 'url1',
        targetURL: 'url2',
        migrationType:1
    },
];

const demoMigrationDate: IMigrationDate[] = [
    {
        id: 1,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 3,
        openSlots: 7,
        maxSitesCounter: 10,
        siteType: 1,

    },
    {
        id: 2,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 10,
        openSlots: 0,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 3,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 1,
        openSlots: 9,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 4,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 3,
        openSlots: 7,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 5,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 10,
        openSlots: 0,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 6,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 1,
        openSlots: 9,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 7,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 3,
        openSlots: 7,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 8,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 10,
        openSlots: 0,
        maxSitesCounter: 10,
        siteType: 1,
    },
    {
        id: 9,
        migrationStartDate: new Date().toISOString(),
        currentSitesCount: 1,
        openSlots: 9,
        maxSitesCounter: 10,
        siteType: 1,
    },
];

export class DataService implements IDataService {
    async getAT(): Promise<string> {
        const token = await Providers.globalProvider.getAccessToken({ scopes: ['User.Read'] });
        return token;
    }

    async fetchAPI(urls: string, action: string = 'GET', data: string | undefined = undefined): Promise<Response> {
        const token = await this.getAT();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('X-Authorization', `Bearer ${token}`);

        return fetch(urls, {
            method: action,
            headers: headers,
            body: data,
        });
    }
    getMyItems(): Promise<IMigrationList[]> {
        if (useMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(demoMigrationListData);
                }, 200);
            });
        } else {
            return this.fetchAPI('/api/GetMyList').then(body => body.json());
        }
    }

    getItem(id: number): Promise<IMigrationList> {
        if (useMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(demoMigrationListData.filter(x => x.id === id)[0]);
                }, 200);
            });
        } else {
            return this.fetchAPI(`/api/GetItem?id=${id}`).then(body => body.json());
        }
    }

    getRefDateItems(type: number): Promise<IMigrationDate[]> {
        if (useMockData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(demoMigrationDate);
                }, 200);
            });
        } else {
            return this.fetchAPI(`/api/GetMigTime?type=${type}`).then(body => body.json());
        }
    }

    updateItemStep1(answer: IAnswer1): Promise<boolean> {
        return this.fetchAPI('/api/updateItemStep1', 'POST', JSON.stringify(answer)).then(body => body.json());
    }

    updateItemStep2(answer: IAnswer2): Promise<boolean> {
        return this.fetchAPI('/api/updateItemStep2', 'POST', JSON.stringify(answer)).then(body => body.json());
    }

    updateItemStep3(answer: IAnswer3): Promise<boolean> {
        return this.fetchAPI('/api/updateItemStep3', 'POST', JSON.stringify(answer)).then(body => body.json());
    }

    approve(id: number): Promise<boolean> {
        const body: IBaseAnswer = { id: id };
        return this.fetchAPI('/api/approve', 'POST', JSON.stringify(body)).then(body => body.json());
    }
}
