import { PrimaryButton } from '@fluentui/react';
import { IMigrationList } from '../../Interfaces/IMigrationItem';
import { LocText } from '../LocText';
import './PageInfo.scss';

export interface IPageInfoProps {
    data: IMigrationList;
}

export const PageInfo: React.FunctionComponent<IPageInfoProps> = (props: React.PropsWithChildren<IPageInfoProps>) => {
    return (
        <div className="page-info">
            <div className="site-title">{props.data.title}</div>
            <div className="url-info">
                <div className="source-url">{props.data.sourceURL}</div>
                <PrimaryButton href={props.data.sourceURL} rel="noreferrer" target="_blank">
                    <LocText text="GOTOSite" />
                </PrimaryButton>
            </div>
        </div>
    );
};
