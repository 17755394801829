import { DefaultButton } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocText } from '../../Components/LocText';
import { useLanguageContext } from '../../Hooks/Translation';
import { IWizardPageProps } from './IWizardPageProps';

//import styles from './SuccessPage.module.scss';

export interface ISuccessPageProps extends IWizardPageProps {}

export function SuccessPage(props: ISuccessPageProps) {
    const { lang, t } = useLanguageContext();
    const navigate = useNavigate();
    const [labels, setLabels] = useState<{ [key: string]: string }>({
        goback: '',
    });
    useEffect(() => {
        const lbls = {
            goback: t('GoBack')?.text || '',
        };
        setLabels(lbls);
    }, [lang, t, setLabels]);

    return (
        <div className="center">
            <p>
                <LocText text="Success" />
            </p>
            <DefaultButton
                text={labels?.goback}
                onClick={() => {
                    navigate('/');
                }}
            />
        </div>
    );
}
