import { createTheme } from '@fluentui/react';

export const palfingerTheme = createTheme({
    palette: {
        themePrimary: '#c00418',
        themeLighterAlt: '#fcf3f4',
        themeLighter: '#f5ced2',
        themeLight: '#eca7ad',
        themeTertiary: '#d95966',
        themeSecondary: '#c71b2d',
        themeDarkAlt: '#ac0314',
        themeDark: '#910311',
        themeDarker: '#6b020d',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
});
