import { Dropdown, IDropdownOption } from '@fluentui/react';
import * as React from 'react';
import { useLanguageContext } from '../Hooks/Translation';

export interface ILocDropDownProps {}

export const LocDropDown: React.FunctionComponent<ILocDropDownProps> = (
    props: React.PropsWithChildren<ILocDropDownProps>
) => {
    const { lang, setLanguage } = useLanguageContext();

    const options: IDropdownOption[] = [
        { key: 'en', text: 'English' },
        { key: 'de', text: 'Deutsch' },
    ];

    return (
        <>
            <Dropdown
                options={options}
                selectedKey={lang}
                onChange={(ev, option) => {
                    if (option?.key) {
                        setLanguage(option.key as string);
                    }
                }}
            />
        </>
    );
};
