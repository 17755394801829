import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { mergeStyles } from '@fluentui/react';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { msalConfig } from './authConfig';
import reportWebVitals from './reportWebVitals';

initializeIcons();
// Inject some global styles
mergeStyles({
    ':global(body,html,#root)': {
        margin: 0,
        padding: 0,
        height: '100vh',
    },
});
/*
const msalInstance = new PublicClientApplication(msalConfig);
Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance,
});
*/
Providers.globalProvider = new Msal2Provider(msalConfig.auth);
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
