import { ILOCLanguage } from '../Interfaces/ILOC';
import { ITranslationService } from './ITranslationService';

const demoTranslationData = {
    de: {
        HeadLine: { text: 'Überschrift' },
        WelcomeInstruction: { html: 'myhtml Text' },
        NoEntryFound: { html: 'keinen eintrag gefunden' },
    },
    en: {
        HeadLine: { text: 'Headline' },
        WelcomeInstruction: { html: 'myhtml Text in english' },
        NoEntryFound: { html: 'No entry found' },
    },
};
const useDemoData = false;
interface locRow {
    language: string;
    title: string;
    text: string;
    longText: string;
}
//"language":"DE","title":"Question1","text":"Sind Sie verantwortlich für diese Seite?","longText":null
export class TranslationService implements ITranslationService {
    async loadTranslations(): Promise<ILOCLanguage> {
        if (useDemoData) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(demoTranslationData);
                }, 500);
            });
        } else {
            const data = await fetch('/api/gettranslations');
            const jsondata: locRow[] = await data.json();

            let globalLOC: any = {};
            jsondata.forEach(x => {
                if (globalLOC[x.language.toLowerCase()] === undefined) {
                    globalLOC[x.language.toLowerCase()] = {};
                }
                globalLOC[x.language.toLowerCase()][x.title] = { text: x.text, html: x.longText };
            });
            return globalLOC;
        }
    }
}
