import { Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListViewItem from '../Components/ListView/ListViewItem';
import { LocText } from '../Components/LocText';
import { IMigrationList } from '../Interfaces/IMigrationItem';
import { DataService } from '../Services/DataService';

//import styles from './WelcomePage.module.scss';

export interface IWelcomePageProps {}

export function WelcomePage(props: IWelcomePageProps) {
    const [data, setData] = useState<IMigrationList[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchData() {
            const datasvc = new DataService();
            setLoading(true);
            const data = await datasvc.getMyItems();
            setData(data);
            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <div>
            <p className="welcome-text">
                <LocText text="WelcomeInstruction" />
            </p>
            <div>
                {loading ? (
                    <Spinner size={SpinnerSize.large} />
                ) : data ? (
                    data.map(item => (
                        <ListViewItem
                            key={item.id}
                            clickable={item.migrationStatus === 'New' || item.migrationStatus === 'Evaluation'}
                            onSelect={() => {
                                navigate('item/' + item.id);
                            }}
                        >
                            <h2>{item.title}</h2>
                            <div>{item.migrationStatus}</div>
                            <div>{item.sourceURL}</div>
                            {item.migrationStatus === 'Closed' && (<div><LocText text="NewLink" /><a href={item.targetURL} target="_blank">{item.targetURL}</a></div>)}
                            {item.migrationStatus !== 'Evaluation' && item.migrationStatus !== 'New' && (<div><LocText text="ApprovedBy" /><span>{item.lastSelectedUser?.lookupValue} ({item.lastSelectedUser?.email})</span></div>)}
                        </ListViewItem>
                    ))
                ) : (
                    <LocText text="NoEntryFound" />
                )}
            </div>
        </div>
    );
}
