import { Icon } from '@fluentui/react';
import './WizardSteps.scss';

export interface IWizardStepsProps {
    stepCount: number;
    currentStep: number;
}

export const WizardSteps: React.FunctionComponent<IWizardStepsProps> = (
    props: React.PropsWithChildren<IWizardStepsProps>
) => {
    return (
        <div className="wizard-steps">
            {Array.from(Array(props.stepCount)).map((x, i) => (
                <Icon
                    key={'stepper' + i}
                    style={{ fontSize: '24px' }}
                    iconName={i === props.currentStep ? 'CircleFill' : 'CircleRing'}
                />
            ))}
        </div>
    );
};
