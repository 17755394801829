import { Spinner, SpinnerSize } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMigrationList } from '../Interfaces/IMigrationItem';
import { DataService } from '../Services/DataService';
import { PickDatePage } from './WizardPages/PickDatePage';
import { QuestionOnePage } from './WizardPages/QuestionOnePage';
import { QuestionTwoPage } from './WizardPages/QuestionTwoPage';
import { SuccessPage } from './WizardPages/SuccessPage';
import { WrapUpPage } from './WizardPages/WrapUpPage';

export interface IWizardPageProperties {}

export enum WizardStep {
    Page1,
    Page2,
    DatePicker,
    WrapUp,
    Success,
}

export const WizardPage: React.FunctionComponent<IWizardPageProperties> = (
    props: React.PropsWithChildren<IWizardPageProperties>
) => {
    const [state, setState] = useState(WizardStep.Page1);
    const [data, setData] = useState<IMigrationList | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams();
    useEffect(() => {
        async function fetchData(itemId: number) {
            const datasvc = new DataService();
            setLoading(true);
            const data = await datasvc.getItem(itemId);
            setData(data);
            setLoading(false);
        }
        if (id) {
            const itemId = parseInt(id);
            fetchData(itemId);
        }
    }, [id]);
    const goToNext = (): void => {
        setState(state + 1);
    };
    const goToPrev = (): void => {
        setState(state - 1);
    };
    const goToSuccess = (): void => {
        setState(WizardStep.Success);
    };
    return (
        <>
            {loading ? (
                <Spinner size={SpinnerSize.large} />
            ) : (
                <>
                    {!!data && state === WizardStep.Page1 && (
                        <QuestionOnePage
                            data={data}
                            img="/assets/PalfingerAppImage1.jpeg"
                            goToNext={goToNext}
                            goToPrev={goToPrev}
                            goToSuccess={goToSuccess}
                        />
                    )}
                    {!!data && state === WizardStep.Page2 && (
                        <QuestionTwoPage
                            data={data}
                            img="/assets/PalfingerAppImage2.jpeg"
                            goToNext={goToNext}
                            goToPrev={goToPrev}
                            goToSuccess={goToSuccess}
                        />
                    )}
                    {!!data && state === WizardStep.DatePicker && (
                        <PickDatePage
                            data={data}
                            img="/assets/PalfingerAppImage3.jpeg"
                            goToNext={goToNext}
                            goToPrev={goToPrev}
                            goToSuccess={goToSuccess}
                        />
                    )}
                    {!!data && state === WizardStep.WrapUp && (
                        <WrapUpPage
                            data={data}
                            img="/assets/PalfingerAppImage3.jpeg"
                            goToNext={goToNext}
                            goToPrev={goToPrev}
                            goToSuccess={goToSuccess}
                        />
                    )}
                    {!!data && state === WizardStep.Success && (
                        <SuccessPage
                            data={data}
                            img="/assets/PalfingerAppImage4.jpeg"
                            goToNext={goToNext}
                            goToPrev={goToPrev}
                            goToSuccess={goToSuccess}
                        />
                    )}
                </>
            )}
        </>
    );
};
