import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ILOCEntry, ILOCLanguage, ILOCValue } from '../Interfaces/ILOC';
import { ITranslationService } from '../Services/ITranslationService';
import { TranslationService } from '../Services/TranslationService';

export interface ILanguageContext {
    lang: string;
    t: (transkey: string) => ILOCValue | undefined;
    setLanguage: (langkey: string) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
    lang: 'en',
    t: (transkey: string) => {
        return { text: undefined, html: undefined };
    },
    setLanguage: (langkey: string) => {},
});

export const useLanguageContext = () => useContext(LanguageContext);
export interface ILanguageProviderProperties {
    children?: any;
}

export const LanguageProvider = ({ children }: ILanguageProviderProperties) => {
    const { lang, t, setLanguage } = useTranslation();

    return <LanguageContext.Provider value={{ lang, t, setLanguage }}>{children}</LanguageContext.Provider>;
};

export function useTranslation() {
    const [value, setValue] = useState<ILOCLanguage | undefined>(undefined);
    const [lang, setLang] = useState<string>('');
    const ref = useRef<ILOCEntry>({});

    useEffect(() => {
        async function fetchData() {
            const translationService: ITranslationService = new TranslationService();
            const data = await translationService.loadTranslations();
            setValue(data);
            setLang('de');
        }
        fetchData();
    }, []);

    const setLanguage = (langKey: string) => {
        if (value) {
            ref.current = value[langKey];
        }
        setLang(langKey);
    };
    const t = (transkey: string): ILOCValue | undefined => {
        if (!!value && !!value[lang] && !!value[lang][transkey]) {
            return value[lang][transkey];
        }
        return undefined;
    };
    return { lang, t, setLanguage };
}
