import { Checkbox, ChoiceGroup, IChoiceGroupOption, Stack } from '@fluentui/react';
import { PeoplePicker, PersonType } from '@microsoft/mgt-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocText } from '../../Components/LocText';
import { PageInfo } from '../../Components/PageInfo/PageInfo';
import { useLanguageContext } from '../../Hooks/Translation';
import { DataService } from '../../Services/DataService';
import { IWizardPageProps } from './IWizardPageProps';
import { WizardTemplate } from './WizardTemplate';

//import styles from './QuestionOnePage.module.scss';

export interface IQuestionOnePageProps extends IWizardPageProps {}

export function QuestionOnePage(props: IQuestionOnePageProps) {
    const { lang, t } = useLanguageContext();
    const navigate = useNavigate();
    const [valid, setValid] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    const [options, setOptions] = useState<IChoiceGroupOption[] | undefined>(undefined);

    const [labels, setLabels] = useState<{ [key: string]: string }>({
        lblCheckbox: '',
    });

    const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
    const [unknownOwner, setUnknownOwner] = useState<boolean>(false);
    const [suggestedOwner, setSuggestedOwner] = useState<string | undefined>(undefined);

    useEffect(() => {
        const data = [
            { key: '1', text: t('Yes')?.text || 'Yes' },
            { key: '0', text: t('No')?.text || 'No' },
        ];
        setOptions(data);
    }, [lang, t]);
    useEffect(() => {
        const lbls = {
            lblCheckbox: t('Question1_SuggestedUnknown')?.text || '',
        };
        setLabels(lbls);
    }, [lang, t, setLabels]);
    useEffect(() => {
        if (selectedKey === '1') {
            setValid(true);
        } else if (selectedKey === '0' && !!unknownOwner) {
            setValid(true);
        } else if (selectedKey === '0' && suggestedOwner) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [selectedKey, unknownOwner, suggestedOwner]);

    const onPrevClick = () => {
        navigate('/');
    };

    const onNextClick = async () => {
        const datasvc = new DataService();

        try {
            setSaving(true);
            setErrorText(undefined);
            await datasvc.updateItemStep1({
                id: props.data.id,
                responsible: selectedKey === '1' ? true : false,
                suggestedOwner: suggestedOwner,
                unknownOwner: unknownOwner,
            });

            if (selectedKey === '1') {
                props.goToNext();
            } else {
                props.goToSuccess();
            }
            return;
        } catch {
            setErrorText(t('Question1_FailedToSave')?.text || t('FailedToSave')?.text || 'Failed to save answer!');
        }
        setSaving(false);
    };

    const handleSelectionChanged = (e: any) => {
        if (!!e.target.selectedPeople && e.target.selectedPeople.length === 1) {
            setSuggestedOwner(e.target.selectedPeople[0].userPrincipalName);
        }
    };

    return (
        <WizardTemplate
            img={props.img}
            showNext={true}
            disableNext={!valid || saving}
            onNextClick={onNextClick}
            showPrev={true}
            disablePrev={false}
            onPrevClick={onPrevClick}
            step={0}
            errorText={errorText}
            showSpinner={saving}
        >
            <div className="question-content">
                <div className="question-text">
                    <div className="question-text-inner">
                        <LocText text="Question1" />
                    </div>
                </div>
                <div className="question-body">
                    <Stack tokens={{ childrenGap: 10 }}>
                        <ChoiceGroup
                            selectedKey={selectedKey}
                            options={options}
                            onChange={(ev, option) => {
                                if (option) {
                                    setSelectedKey(option?.key);
                                }
                            }}
                        />
                        {selectedKey === '0' && (
                            <>
                                <LocText text="Question1_Suggested" />

                                <PeoplePicker
                                    type={PersonType.person}
                                    disabled={unknownOwner}
                                    selectionMode={'single'}
                                    selectionChanged={handleSelectionChanged}
                                />
                                <Checkbox
                                    label={labels?.lblCheckbox}
                                    checked={unknownOwner}
                                    onChange={(ev, checked) => {
                                        setUnknownOwner(checked ? true : false);
                                    }}
                                />
                            </>
                        )}
                    </Stack>
                </div>

                <div className="flex-filler"></div>

                <PageInfo data={props.data} />
            </div>
        </WizardTemplate>
    );
}
