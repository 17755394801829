import { IconButton, MessageBar, MessageBarType, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { WizardSteps } from '../../Components/WizardSteps/WizardSteps';
import './WizardTemplate.scss';

export interface IWizardTemplateProps {
    showNext: boolean;
    disableNext: boolean;
    showPrev: boolean;
    disablePrev: boolean;
    children: any;
    img: string;
    step?: number;
    errorText?: string;
    showSpinner?: boolean;
    onPrevClick?: () => void;
    onNextClick?: () => void;
}

const wizardCount = 4;

export function WizardTemplate(props: IWizardTemplateProps) {
    return (
        <div className="wizard-container">
            <div className="wizard-content">
                {props.errorText && <MessageBar messageBarType={MessageBarType.error}>{props.errorText}</MessageBar>}
                <Stack horizontal={true} verticalAlign="center" grow={1} styles={{ root: { overflowY: 'hidden' } }}>
                    <div className="wizard-prev">
                        {props.showPrev && (
                            <IconButton
                                className="navbtn"
                                iconProps={{ iconName: 'ChevronLeftSmall' }}
                                disabled={props.disablePrev}
                                onClick={() => {
                                    if (props.onPrevClick) props.onPrevClick();
                                }}
                            />
                        )}
                    </div>
                    <div className="wizard-body">{props.children}</div>
                    <div className="wizard-next">
                        {props.showNext && (
                            <IconButton
                                className="navbtn"
                                iconProps={{ iconName: 'ChevronRightSmall' }}
                                disabled={props.disableNext}
                                onClick={() => {
                                    if (props.onNextClick) props.onNextClick();
                                }}
                            />
                        )}
                    </div>

                    {props.showSpinner && (
                        <div className="wizard-spinner">
                            <Spinner size={SpinnerSize.large} />
                        </div>
                    )}
                </Stack>
                {props.step !== undefined && (
                    <div className="footer-info">
                        <WizardSteps stepCount={wizardCount} currentStep={props.step!} />
                    </div>
                )}
            </div>
            <div className="wizard-image">
                <img src={props.img} alt="" />
            </div>
        </div>
    );
}
