import { PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LocText } from '../../Components/LocText';
import { useLanguageContext } from '../../Hooks/Translation';
import { IMigrationList } from '../../Interfaces/IMigrationItem';
import { DataService } from '../../Services/DataService';
import { IWizardPageProps } from './IWizardPageProps';
import { WizardTemplate } from './WizardTemplate';
import './WrapUpPage.scss';

//import styles from './SuccessPage.module.scss';

export interface IWrapUpPageProps extends IWizardPageProps {}

export function WrapUpPage(props: IWrapUpPageProps) {
    const { lang, t } = useLanguageContext();

    const [labels, setLabels] = useState<{ [key: string]: string }>({
        approve: '',
    });
    const [data, setData] = useState<IMigrationList | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    const { id } = useParams();

    useEffect(() => {
        async function fetchData(itemId: number) {
            const datasvc = new DataService();
            try {
                setLoading(true);
                setErrorText(undefined);
                const data = await datasvc.getItem(itemId);
                setData(data);
            } catch {
                setErrorText(t('NoEntryFound')?.text || 'Could not load entry');
            }
            setLoading(false);
        }

        if (id) {
            const itemId = parseInt(id);
            fetchData(itemId);
        }
    }, [id, t]);

    useEffect(() => {
        const lbls = {
            approve: t('Approve')?.text || '',
        };
        setLabels(lbls);
    }, [lang, t, setLabels]);

    const onPrevClick = () => {
        props.goToPrev();
    };

    const goToSuccess = async () => {
        //save Data
        const datasvc = new DataService();

        try {
            setSaving(true);
            await datasvc.approve(props.data.id);
            props.goToNext();
            return;
        } catch {
            setErrorText(t('WrapUpPage_FailedToSave')?.text || 'Failed to approve migration state');
        }
        setSaving(false);
    };

    return (
        <WizardTemplate
            img={props.img}
            showNext={false}
            disableNext={false}
            showPrev={true}
            disablePrev={false}
            onPrevClick={onPrevClick}
            showSpinner={loading || saving}
            errorText={errorText}
            step={3}
        >
            <div className="question-content">
                <div className="question-text">
                    <div className="question-text-inner">
                        <LocText text="WrapUp" />
                    </div>
                </div>

                <div className="question-body">
                    <Stack tokens={{ childrenGap: 10 }}>
                        {loading ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            data && (
                                <>
                                    <div>
                                        <div className="label">
                                            <LocText text="WrapUp_PageName" />
                                        </div>
                                        <div className="value">{data.title}</div>
                                    </div>
                                    <div>
                                        <div className="label">
                                            <LocText text="WrapUp_PageUrl" />
                                        </div>
                                        <div className="value">
                                            <a href={data.sourceURL}>{data.sourceURL}</a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="label">
                                            <LocText text="WrapUp_InUse" />
                                        </div>
                                        <div className="value">
                                            {!data.unused ? t('Yes')?.text || 'Yes' : t('No')?.text || 'No'}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="label">
                                            <LocText text="WrapUp_SuggestedDate" />
                                        </div>
                                        <div className="value">
                                            {data.suggestedDate && new Date(data.suggestedDate).toLocaleDateString()}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="label">
                                            <LocText text="WrapUp_TargetUrl" />
                                        </div>
                                        <div className="value">
                                            {data.targetURL}
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </Stack>
                </div>

                <div className="flex-filler"></div>

                <div className="text-center">
                    <PrimaryButton size={40} text={labels.approve} onClick={goToSuccess} />
                </div>
            </div>
        </WizardTemplate>
    );
}
