import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        //Cubido Client ID for People Picker test
       /* clientId: 'f05f3131-5842-497e-9a34-b9f74705ac9b', //`${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}`,
       authority: `https://login.microsoftonline.com/810b7396-6266-4db3-92c6-89d1220932de`, //${process.env["REACT_APP_AAD_APP_TENANT_ID"]}`,
         redirectUri: 'http://localhost:3000', //`${process.env["REACT_APP_AAD_APP_REDIRECT_URI"]}`,
        postLogoutRedirectUri: 'http://localhost:3000/',
        */

        clientId: '82bf586a-10c0-4be7-a8f0-391c6d689b3c', //`${process.env["REACT_APP_AAD_APP_CLIENT_ID"]}`,
        authority: `https://login.microsoftonline.com/6769af3e-92cd-439b-9059-74c409b76204`, //${process.env["REACT_APP_AAD_APP_TENANT_ID"]}`,
        redirectUri: 'https://spmigration.palfinger.com', //`${process.env["REACT_APP_AAD_APP_REDIRECT_URI"]}`,
        postLogoutRedirectUri: 'https://spmigration.palfinger.com/', //`${process.env["REACT_APP_AAD_APP_REDIRECT_URI"]}`,
        navigateToLoginRequestUrl: false,
       
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.info(message);
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: ['User.Read', 'User.AllRead'],
};
